/*==========================================================================

	Template Elements Stylesheet
	____________________________________________________________

	Client:		MJS Floorsanding
	Date:		6th December 2018
	Author:		Edge Marketing Solutions

===========================================================================*/
/*=========================================================================*/
/* [Global Template Styles] */
body {
  color: #434343;
  font-family: "Open Sans", sans-serif;
}

a {
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  color: #da2027;
}

a:hover {
  color: #f1a2a5;
}

.btn-primary {
  border: none;
  background-color: #da2027;
  color: white !important;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #550c0f;
}

h1 {
  margin: 0 0 2rem 0;
  font-weight: 700;
}

h2 {
  margin: 2rem 0;
  font-weight: 400;
}

h3 {
  margin: 2rem 0;
  font-weight: 700;
}

h4 {
  margin: 2rem 0;
  font-weight: 400;
}

h5 {
  margin: 2rem 0 0.5rem;
  font-weight: 700;
}

h6 {
  margin: 2rem 0 0.5rem;
  font-weight: 400;
}

img {
  max-width: 100%;
}

img.img-lightbox {
  cursor: zoom-in;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  h1 {
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.6rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.3rem;
  }
  h6 {
    font-size: 1.2rem;
  }
}

/*=========================================================================*/
/* [Header Template Styles] */
.header {
  width: 100%;
  position: relative;
  padding: 1.5rem 3rem 0 3rem;
  z-index: 200;
}

.header .header-contact {
  text-align: right;
  font-weight: 300;
  font-size: 1.6rem;
  color: #da2027;
}

.header .header-contact a {
  color: white;
}

.header .header-contact a:hover {
  text-decoration: none;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .header {
    padding: 1rem 2rem 0 2rem;
  }
  .header .header-contact {
    font-size: 1.4rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .header {
    padding: 1rem;
    background-color: black;
    text-align: center;
  }
  .header .header-contact {
    margin: 1.5rem 0;
    text-align: center;
    font-size: 1.4rem;
  }
}

/*=========================================================================*/
/* [Navigation Template Styles] */
.navbar-dark {
  margin: 1rem 0 0 0;
  padding: 0;
}

.navbar-dark .navbar-collapse {
  justify-content: flex-end;
}

.navbar-dark .navbar-nav {
  border-bottom: 1px solid #da2027;
}

.navbar-dark .navbar-nav .nav-link {
  padding: 0 1.25rem;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #da2027;
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #da2027;
}

.navbar-dark .navbar-nav .nav-item:first-child .nav-link {
  padding-left: 0;
}

.navbar-dark .navbar-nav .nav-item:last-child .nav-link {
  padding-right: 0;
}

.navbar-dark .navbar-nav .dropdown-item.active,
.navbar-dark .navbar-nav .dropdown-item:active {
  background-color: #da2027;
}

/* Desktop */
@media (min-width: 768px) {
  .navbar-dark .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.4);
    border: none;
  }
  .navbar-dark .dropdown-menu .dropdown-item {
    color: white;
  }
}

/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-dark .nav-link {
    padding: 0 1rem;
    font-size: 0.9rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .navbar-dark {
    margin: 0;
  }
  .navbar-dark .navbar-toggler {
    width: 100%;
    padding: 1rem 0;
    border: 1px solid white;
    color: white;
  }
  .navbar-dark .navbar-nav .nav-link {
    width: 100%;
    padding: 0.5rem;
    text-align: center;
  }
  .navbar-dark .dropdown-menu {
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.4);
    border: none;
  }
  .navbar-dark .dropdown-menu .dropdown-item {
    color: white;
    text-align: center;
  }
}

/*=========================================================================*/
/* [Page Template Styles] */
.page {
  width: 100%;
  padding: 4rem 0;
  position: relative;
}

.page .container {
  position: relative;
}

.page .container:before {
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
  content: ' ';
  position: absolute;
  top: -2rem;
  border-top: 1px solid #da2027;
}

.sidebar h5 {
  margin: 0 0 0.5rem 0;
}

.sidebar .nav .nav-item {
  border-top: 1px solid rgba(67, 67, 67, 0.1);
}

.sidebar .nav .nav-item .nav-link {
  padding: 0.5rem 0;
}

.subpage-banner {
  width: 100%;
  height: 600px;
  position: relative;
  z-index: 1;
  margin-top: -130px;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

.subpage-banner h1 {
  position: absolute;
  bottom: 20%;
  width: 100%;
  color: white;
  text-transform: uppercase;
  font-size: 2.3rem;
}

.subpage-banner:before {
  position: absolute;
  content: ' ';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.swiper-container {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
  margin-top: -130px;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

.swiper-container .swiper-wrapper .swiper-slide {
  width: 100%;
  height: 100%;
}

.swiper-container .swiper-wrapper .swiper-slide .swiper-slide-image {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: bottom center;
}

.swiper-container .swiper-wrapper .swiper-slide .swiper-slide-image:before {
  position: absolute;
  content: ' ';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.swiper-container .swiper-wrapper .swiper-slide .swiper-text {
  z-index: 1000;
  position: absolute;
  top: 50%;
  width: 100%;
  padding: 0 3rem;
  box-sizing: border-box;
  color: white;
}

.swiper-container .swiper-wrapper .swiper-slide .swiper-text p {
  font-weight: 300;
  font-size: 2.5rem;
  margin: 0;
}

.swiper-container .swiper-wrapper .swiper-slide .swiper-text p strong {
  font-weight: 700;
}

.feature-nav {
  width: 100%;
  display: flex;
}

.feature-nav .item {
  width: 50%;
  flex-basis: 50%;
}

.feature-nav .item a {
  padding: 2rem 3rem;
  display: block;
  position: relative;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  font-size: 1.7rem;
  font-weight: 700;
}

.feature-nav .item a small {
  font-weight: 400;
  color: #da2027;
}

.feature-nav .item a:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.8);
}

.gallery-hover {
  position: relative;
}

.gallery-link {
  position: relative;
  width: 100%;
  height: 100%;
}

.hover-caption {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
  display: none;
  overflow: hidden;
}

.hover-caption .caption {
  position: absolute;
  color: white;
  text-align: center;
  padding: 15%;
  top: 50%;
  transform: translateY(-50%);
}

.homepage-overlay {
  background-image: url(../images/homepage-overlay.svg);
  background-repeat: no-repeat;
  background-position: center;
}

/* Large desktop */
/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .subpage-banner {
    width: 100%;
    height: 500px;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .subpage-banner {
    width: 100%;
    height: 500px;
  }
  .swiper-container .swiper-wrapper .swiper-slide .swiper-text h1 {
    font-size: 2rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .subpage-banner {
    height: auto;
    margin-top: 0;
  }
  .subpage-banner h1 {
    position: relative;
    padding: 4rem 1rem;
    margin: 0;
    font-size: 1.6rem;
  }
  .page {
    padding: 2rem 0.75rem;
  }
  .page .container:before {
    display: none;
  }
  .page .sidebar {
    display: none;
  }
  .swiper-container {
    height: 70vh;
  }
  .swiper-container .swiper-wrapper .swiper-slide .swiper-text p {
    font-size: 1.8rem;
  }
  .feature-nav {
    display: block;
  }
  .feature-nav .item {
    width: 100%;
  }
}

/*=========================================================================*/
/* [Footer Template Styles] */
.footer {
  width: 100%;
  padding: 3rem;
  font-size: 0.8rem;
}

.footer a {
  color: #434343;
}

.footer a:hover {
  color: #da2027;
  text-decoration: none;
}

.footer .nav .nav-link {
  text-transform: uppercase;
}

.footer .nav .nav-link:first-child {
  padding-left: 0;
}

.footer .footnotes {
  opacity: 0.5;
  font-size: 0.7rem;
}

.footer .footer-contact {
  color: #da2027;
  font-size: 1.2rem;
}

.footer-message {
  width: 100%;
  padding: 5rem 2rem;
  background-image: url(../images/footer-banner-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  text-align: center;
}

.footer-message h5 {
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
  color: white;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .footer {
    padding: 2rem 1rem;
    font-size: 0.7rem;
  }
  .footer .nav .nav-link {
    padding: 0.5rem 0.75rem;
  }
  .footer-message {
    padding: 3rem 2rem;
  }
  .footer-message h5 {
    font-size: 1.7rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .footer {
    padding: 2em 1rem;
    font-size: 0.8rem;
    text-align: center;
  }
  .footer .nav {
    display: block;
  }
  .footer .nav .nav-link {
    padding: 0.5rem 0rem;
  }
  .footer .footnotes {
    display: none;
  }
  .footer-message {
    padding: 2rem 1.5rem;
  }
  .footer-message h5 {
    font-size: 1.4rem;
  }
}

/*=========================================================================*/
/* [Table as row override Styles] */
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

/* col-lg */
@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

/* col-md */
@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

/* col-sm */
@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

/* col-xs */
table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}

.card {
  border-radius: 0;
}

.card-header {
  background-color: #fcfcfc;
}

.card-header > h3 > button {
  color: #434343;
}
