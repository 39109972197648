/*==========================================================================

	Template Elements Stylesheet
	____________________________________________________________

	Client:		MJS Floorsanding
	Date:		6th December 2018
	Author:		Edge Marketing Solutions

===========================================================================*/

	$color-grey: rgb(67, 67, 67);
	$color-red: rgb(218, 32, 39);
	$color-black: rgb(0, 0, 0);
	$color-white: rgb(255, 255, 255);

	$font-default: 'Open Sans', sans-serif;

/*=========================================================================*/

	/* [Global Template Styles] */

	body
	{
		color: $color-grey;
		font-family: $font-default;
	}

	a
	{
		-webkit-transition: all 150ms ease-in-out;
		-moz-transition: all 150ms ease-in-out;
		-ms-transition: all 150ms ease-in-out;
		-o-transition: all 150ms ease-in-out;
		transition: all 150ms ease-in-out;

		color: $color-red;
	}

		a:hover
		{
			color: lighten($color-red, 30%);
		}

	.btn-primary
	{
		border: none;
		background-color: $color-red;

		color: $color-white !important;
	}

		.btn-primary:hover,
		.btn-primary:focus
		{
			background-color: darken($color-red, 30%);
		}

	h1
	{
		margin: 0 0 2rem 0;

		font-weight: 700;
	}

	h2
	{
		margin: 2rem 0;

		font-weight: 400;
	}

	h3
	{
		margin: 2rem 0;

		font-weight: 700;
	}

	h4
	{
		margin: 2rem 0;

		font-weight: 400;
	}

	h5
	{
		margin: 2rem 0 0.5rem;

		font-weight: 700;
	}

	h6
	{
		margin: 2rem 0 0.5rem;

		font-weight: 400;
	}

	img
	{
		max-width: 100%;
	}

	img.img-lightbox
	{
		cursor: zoom-in;
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		h1
		{
			font-size: 1.8rem;
		}

		h2
		{
			font-size: 1.6rem;
		}

		h3
		{
			font-size: 1.5rem;
		}

		h4
		{
			font-size: 1.4rem;
		}

		h5
		{
			font-size: 1.3rem;
		}

		h6
		{
			font-size: 1.2rem;
		}
	}

	/*=========================================================================*/

	/* [Header Template Styles] */

	.header
	{
		width: 100%;
		position: relative;
		padding: 1.5rem 3rem 0 3rem;
		z-index: 200;
		
		.header-contact
		{
			text-align: right;
			font-weight: 300;
			font-size: 1.6rem;
			color: $color-red;

			a
			{
				color: $color-white;
			}
			
			a:hover
			{
				text-decoration: none;
			}
		}
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.header
		{
			padding: 1rem 2rem 0 2rem;

			.header-contact
			{
				font-size: 1.4rem;
			}
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.header
		{
			padding: 1rem;

			background-color: $color-black;

			text-align: center;

			.header-contact
			{
				margin: 1.5rem 0;

				text-align: center;
				font-size: 1.4rem;
			}
		}
	}

	/*=========================================================================*/

	/* [Navigation Template Styles] */

	.navbar-dark
	{
		margin: 1rem 0 0 0;
		padding: 0;

		.navbar-collapse
		{
			justify-content: flex-end;
		}

		.navbar-nav
		{
			border-bottom: 1px solid $color-red;

			.nav-link
			{
				padding: 0 1.25rem;

				color: $color-white;
				text-transform: uppercase;
				font-weight: 700;
			}

			.nav-link:hover,
			.nav-link:focus
			{
				color: $color-red;
			}

			.show > .nav-link,
			.active > .nav-link,
			.nav-link.show,
			.nav-link.active
			{
				color: $color-red;
			}

			.nav-item:first-child .nav-link
			{
				padding-left: 0;
			}

			.nav-item:last-child .nav-link
			{
				padding-right: 0;
			}

			.dropdown-item.active,
			.dropdown-item:active
			{
				background-color: $color-red;
			}
		}
	}

	/* Desktop */
	@media (min-width: 768px) {
		.navbar-dark
		{
			.dropdown-menu
			{
				left: 50%;
				transform: translateX(-50%);

				border-radius: 0;
				background-color: transparentize($color-white, 0.6);
				border: none;

				.dropdown-item
				{
					color: $color-white;
				}
			}
		}
	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.navbar-dark {
			.nav-link {
				padding: 0 1rem;
				font-size: 0.9rem;
			}
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.navbar-dark
		{
			margin: 0;

			.navbar-toggler{
				width: 100%;
				padding: 1rem 0;

				border: 1px solid $color-white;

				color: $color-white;
			}

			.navbar-nav {
				.nav-link {
					width: 100%;
					padding: 0.5rem;

					text-align: center;
				}
			}

			.dropdown-menu
			{
				border-radius: 0;
				background-color: transparentize($color-white, 0.6);
				border: none;

				.dropdown-item
				{
					color: $color-white;
					text-align: center;
				}
			}
		}
	}

	/*=========================================================================*/

	/* [Page Template Styles] */

	.page
	{
		width: 100%;
		padding: 4rem 0;
		position: relative;

		.container
		{
			position: relative;
		}

		.container:before
		{
			width: 60%;
			left: 50%;
			transform: translateX(-50%);
			content: ' ';
			position: absolute;
			top: -2rem;

			border-top: 1px solid $color-red;
		}
	}

		.sidebar
		{
			h5
			{
				margin: 0 0 0.5rem 0;
			}

			.nav
			{
				.nav-item
				{
					border-top: 1px solid transparentize($color-grey, 0.9);

					.nav-link
					{
						padding: 0.5rem 0;
					}
				}
			}
		}

	.subpage-banner
	{
		width: 100%;
		height: 600px;
		position: relative;
		z-index: 1;
		margin-top: -130px;

		background-size: cover;
		background-repeat: no-repeat;

		text-align: center;

		h1
		{
			position: absolute;
			bottom: 20%;
			width: 100%;


			color: $color-white;
			text-transform: uppercase;
			font-size: 2.3rem;
		}
	}

	.subpage-banner:before
	{
		position: absolute;
		content: ' ';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		background-color: transparentize($color-black, 0.6);
	}

	.swiper-container
	{
		width: 100%;
		height: 100vh;
		position: relative;
		z-index: 1;
		margin-top: -130px;

		background-size: cover;
		background-repeat: no-repeat;

		text-align: center;

		.swiper-wrapper
		{
			.swiper-slide
			{
				width: 100%;
				height: 100%;

				.swiper-slide-image
				{
					width: 100%;
					height: 100%;
					position: relative;

					background-size: cover;
					background-position: bottom center;
				}

				.swiper-slide-image:before
				{
					position: absolute;
					content: ' ';
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;

					background-color: transparentize($color-black, 0.6);
				}

				.swiper-text
				{
					z-index: 1000;
					position: absolute;
					top: 50%;
					width: 100%;
					padding: 0 3rem;
					box-sizing: border-box;

					color: $color-white;

					p
					{
						font-weight: 300;
						font-size: 2.5rem;
						margin: 0;

						strong
						{
							font-weight: 700;
						}
					}
				}
			}
		}
	}

	.feature-nav
	{
		width: 100%;
		display: flex;

		.item
		{
			width: 50%;
			flex-basis: 50%;

			a
			{
				padding: 2rem 3rem;
				display: block;
				position: relative;
				height: 100%;

				background-color: transparentize($color-black, 0.6);

				color: $color-white;
				font-size: 1.7rem;
				font-weight: 700;

				small
				{
					font-weight: 400;
					color: $color-red;
				}
			}

			a:hover
			{
				text-decoration: none;

				background-color: transparentize($color-black, 0.2);
			}
		}
	}

	.gallery-hover
	{
		position: relative;
	}

	.gallery-link
	{
		position: relative;
		width: 100%;
		height: 100%;
	}

	.hover-caption
	{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.75);
		z-index: 10;
		display: none;
		overflow: hidden;

		.caption
		{
			position: absolute;
			color: white;
			text-align: center;
			padding: 15%;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.homepage-overlay
	{
		background-image: url(../images/homepage-overlay.svg);
		background-repeat: no-repeat;
		background-position: center;
	}
	

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.subpage-banner {
			width: 100%;
			height: 500px;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.subpage-banner {
			width: 100%;
			height: 500px;
		}

		.swiper-container
		{
			.swiper-wrapper
			{
				.swiper-slide
				{
					.swiper-text
					{
						h1
						{
							font-size: 2rem;
						}
					}
				}
			}
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.subpage-banner {
			height: auto;
			margin-top: 0;

			h1
			{
				position: relative;
				padding: 4rem 1rem;
				margin: 0;

				font-size: 1.6rem;
			}
		}

		.page
		{
			padding: 2rem 0.75rem;

			.container:before
			{
				display: none;
			}

			.sidebar
			{
				display: none;
			}
		}

		.swiper-container
		{
			height: 70vh;

			.swiper-wrapper
			{
				.swiper-slide
				{
					.swiper-text
					{
						p
						{
							font-size: 1.8rem;
						}
					}
				}
			}
		}

		.feature-nav {
			display: block;

			.item {
				width: 100%;
			}
		}
	}

	/*=========================================================================*/

	/* [Footer Template Styles] */

	.footer
	{
		width: 100%;
		padding: 3rem;

		font-size: 0.8rem;

		a
		{
			color: $color-grey;
		}

			a:hover
			{
				color: $color-red;
				text-decoration: none;
			}

		.nav
		{
			.nav-link
			{
				text-transform: uppercase;
			}

			.nav-link:first-child
			{
				padding-left: 0;
			}
		}

		.footnotes
		{
			opacity: 0.5;
			font-size: 0.7rem;
		}

		.footer-contact
		{
			color: $color-red;
			font-size: 1.2rem;
		}
	}

	.footer-message
	{
		width: 100%;
		padding: 5rem 2rem;

		background-image: url(../images/footer-banner-bg.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;

		text-align: center;

		h5
		{
			margin: 0;

			font-size: 2rem;
			font-weight: 700;
          	color: $color-white;
		}
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.footer
		{
			padding: 2rem 1rem;

			font-size: 0.7rem;

			.nav
			{
				.nav-link
				{
					padding: 0.5rem 0.75rem;
				}
			}
		}

		.footer-message
		{
			padding: 3rem 2rem;

			h5
			{
				font-size: 1.7rem;
			}
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.footer
		{
			padding: 2em 1rem;

			font-size: 0.8rem;
			text-align: center;

			.nav
			{
				display: block;
				
				.nav-link
				{
					padding: 0.5rem 0rem;
				}
			}

			.footnotes
			{
				display: none;
			}
		}

		.footer-message
		{
			padding: 2rem 1.5rem;

			h5
			{
				font-size: 1.4rem;
			}
		}
	}

	/*=========================================================================*/

	/* [Table as row override Styles] */

	table.table-as-row > tbody > tr:before,
	table.table-as-row > tbody > tr:after {
		content: " ";
		display: table;
		clear: both;
	}

	table.table-as-row > tbody > tr {
		height: auto !important;
	}

	table.table-as-row,
	table.table-as-row > tbody,
	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
	}

	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
		margin-right: -15px;
		margin-left: -15px;
	}

	table.table-as-row > tbody > tr > td[class*=col-] {
		display: block;
		height: auto !important;
		margin-bottom: 20px;
	}

	/* col-lg */
	@media (min-width: 1200px) {
		table.table-as-row > tbody > tr > td[class*=col-lg-] {
			float: left;
		}
	}

	/* col-md */
	@media (min-width: 992px) {
		table.table-as-row > tbody > tr > td[class*=col-md-] {
			float: left;
		}
	}

	/* col-sm */
	@media (min-width: 768px) {
		table.table-as-row > tbody > tr > td[class*=col-sm-] {
			float: left;
		}
	}

	/* col-xs */
	table.table-as-row > tbody > tr > td[class*=col-xs-] {
		float: left;
	}

	.card {
        border-radius: 0;
    }
    
    .card-header {
        background-color: #fcfcfc;
    }
    
    .card-header > h3 > button {
        color: #434343;
    }